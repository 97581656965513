// ==========================================================================
// PLACEHOLDERS
// ==========================================================================

// Clearfix

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


// Vertical  Aligns

%v-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

%v-align-sm {
  @include media-breakpoint-up(sm) { 
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

%v-align-md {
  @include media-breakpoint-up(md) { 
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

%v-align-lg {
  @include media-breakpoint-up(lg) { 
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}


// Horizontal  aligns

%h-align {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}


// Centered

%centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%centered-md {
  @include media-breakpoint-up(md) { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}



// Full link

%full-link {
    display:block;
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
}


// Reset List

%reset-list {
  padding:0;
  margin:0;

  li {
    list-style:none;
  }
}


// Text ellipsis

%ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// Background Covers

%bg-cover{
  background-size:cover;
  background-position:center;
}

%svg-cover{
  background-size:auto 100%;
  background-position:center;
  background-repeat:no-repeat;
}

// For pseudo-elements

%pseudos {
  display: block;
  content: ''; 
  position: absolute;
}

// Object fit

%object-fit {
    position:absolute;
    width:100%; 
    height:100%;
    object-fit: cover;
    top: 0;     
    left: 0;
}



// Font weights

%font-light {
  font-weight:400;
  font-family:Brother1816Printed-Light;
}

%font-regular {
    font-weight:400;
    font-family:Brother1816Printed-Regular;
}


%font-bold { 
  font-weight:400;
  font-family:Brother1816Printed-Bold; 
}


%font-extrabold { 
    font-weight:400;
    font-family:Brother1816Printed-ExtraBold;
}

%font-black { 
    font-weight:400;
    font-family:Brother1816Printed-Black;
}


// Utils

%hide-mobile {
    display:none;
    
    @include media-breakpoint-up(lg) {
        display:block; 
    }
}

%hide-desktop {
    display:block;
    
    @include media-breakpoint-up(lg) {
        display:none; 
    }
}





