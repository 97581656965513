// ==========================================================================
// Header
// ==========================================================================

// 1.Config

// 2.Base

.header {
    background:#fff;
    left:0;

    border-bottom:1px solid $gray-200;
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 100;

    
	.container-fluid {
		@extend %clearfix;
        position:relative;
        height: 60px;
        text-align:right;

        @include media-breakpoint-up(md)  { 
            height: 100px;  
        }


	}

    &__logo {
        width: 120px;  
        @extend %v-align;
        display: block;
        position:absolute;
       

        @include media-breakpoint-up(md)  { 
            width: 190px;  
            float:left;

        }


        
        img {
            width: 100%;
        }
    }
 
}


.lang {
    @extend %reset-list;
    line-height: 37px;
    display:inline-block;
    right:15px;
    bottom:24px;
    position:absolute;

    @include media-breakpoint-up(md)  { 
        position:relative;
        bottom:inherit;
        right:inherit;
        margin-top:55px;
    }



    &__item {
        display:inline-block;
        text-transform:uppercase;
        font-size: 15px;
        font-weight:500;
        color:$gray-300;

        a {
            color:$gray-300;
            &:hover {
                text-decoration:none;
                color:$gray-500;
            }

        }

        &:before {
            display:inline-block;
            content:'|';
            padding: 0 8px 0 3px;
        }

        &:first-child:before {
            content: none;
        }

        &--active {
            color:$gray-500;
        }

    }
}

.social-links {
    @extend %reset-list;
    line-height: 37px;
    display:inline-block;
    position: absolute;
    right:11px;
    bottom:-3px;

    @include media-breakpoint-up(md)  { 
        position:relative;
        bottom:inherit;
        right:inherit;
        margin-right:15px;
        margin-top:55px;
    }

    &__item {
        display:inline-block;
        color:$gray-500;
        font-size: 16px;
        margin:0 3px;

        a {
            color:$gray-500;
            
            &:hover {
                color:$primary;
            }
    
        }

    }
}




