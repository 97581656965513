// ==========================================================================
// Container
// ==========================================================================


// 1.Config

// 2.Base
.footer{
  	background-image: radial-gradient(circle at 50% 27%, #bb0a7e, #912669 44%);
  	position:relative;
  	overflow:hidden;
  	z-index:0;

  	&:before {
  		@extend %pseudos;
  		left:50%;
  		margin-left:-1500px;
  		height:250px;
  		width:3000px;
  		background:#fff;
  		transform: rotate(-6deg);
  		height: 270px;
  		top: -160px;
  		z-index:1;
  	}

  	.container-fluid {
  		position:relative;
  		height: 245px;	

  		@include media-breakpoint-up(lg)  { 
  			height: 345px;	
  		}
  	}


  	img {
  		width:150px;
  		height:auto;
  		right:40px;
  		bottom:45px;
  		position:absolute;

  		@include media-breakpoint-up(lg)  { 
  			width:343px;
  		}


  	}
}