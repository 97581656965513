// ==========================================================================
// Form elements
// ==========================================================================

// 1.Config

// 2.Base

.form-group {
	margin:0 0 20px;

	&.error {
		.select2-container--default .select2-selection--single, input { border-color:$red !important; }
		.g-recaptcha>div { border:1px solid $red !important; }
	}

	.form-group--checkbox label.error {
		input + span:before { border-color:$red !important; }
	}

	button[disabled] {
		background:$gray-900;
		border:$gray-900;
		cursor:default;
		opacity:.5;

		&:hover { background:$gray-900; }
	}

	span.text {
		@include media-breakpoint-up(sm)  { 
	    	font-size:18px;
	    }	 
	}

	small {
		display:block;
		line-height:1.4;
		margin-top:5px;
	}
}


.form-control {
	display: block;
	width: 100%;
	background: transparent;
    border-radius: 0;
    color: #1f1f1f;
    padding-left:0;
    padding-right:0;
    outline: 0;
    border:0;
    border-bottom: 1px solid rgba($black,.5);
    height: 53px;
    line-height:53px;

    @include media-breakpoint-up(sm)  { 
    	font-size:18px;
    }	 

    &::placeholder {
	    color: #1f1f1f;
	    opacity: 1; /* fix Firefox */
	}

	&:-ms-input-placeholder {
	    color: #1f1f1f;
	}

	&::-ms-input-placeholder {
	    color: #1f1f1f;
	}

	&:focus, &:active {
    	outline: 0;
    }

    &:focus {
    	border-color:$black;
    }

    &.error {
    	border-color:$red !important;
    }   
}


.form-group--checkbox {
		color:$gray-900;
		text-align:left;
		margin:25px 0;
		

		label {
			cursor:pointer;
			font-size:14px;
			line-height:20px;
		    padding-left:30px;
			position:relative;
			

			@include media-breakpoint-up(sm)  {
				padding-left:35px;
				padding-top:3px;
			}

			input {
		    	opacity: 0;
		    	position: absolute;
		  	}

		  	input + span:before {
		  		background:transparent;
		  		border:1px solid $gray-900;
		  		border-radius:0;
		  		color:$gray-900;
		  		content: '';
		  		display: inline-block;
		  		font-size:15px;
		  		height: 20px;
		  		left:0;
		  		line-height:20px;
			    padding: 0;
			    position:absolute;
			    text-align: center;
			    top:0;
			    vertical-align: middle;
			    width:20px;

			    @include media-breakpoint-up(sm)  { 
			    	height: 24px;
			  		line-height:22px;
			    	width:24px;
			    }
			}

			input.error + span:before {
				border-color:$red !important;
			}

			input:checked + span:before {
		    	content: 'X';
		  	}
		}

		span > span > span { margin:0; }

		a {
			color:$gray-900;
			text-decoration:underline;
		}
	}



.btn {
	cursor:pointer;
	border-radius: 27px;
	text-transform: uppercase;
	border: 1px solid #1f1f1f;
	background: transparent;
	border: 1px solid #1f1f1f;
	color: #1f1f1f;
	min-width: 220px !important;
	font-size: 1rem;
	text-align: center;
	transition: all .2s ease-in-out;
	padding: 0 20px;
	line-height: 54px;
	height:54px;
	font-size: 21px;
	transition: $transition-base;
	width:100%;

	@include media-breakpoint-up(md)  { 
		width:auto;
	}


	&:hover {
		text-decoration: none;
		color:$white;
		background:$primary;
		border:1px solid $primary;

	}
}


.btn-add {
	cursor:pointer;
	border-radius: 27px;
	background:#98226f;
	border:1px solid #98226f;	
	color:#fff;
	min-width: 160px !important;
	text-align: center;
	transition: all .2s ease-in-out;
	padding: 0 10px;
	line-height: 42px;
	font-size: 15px;
	transition: $transition-base;
	max-height:44px;

	&:hover {
		//border:none;
		text-decoration: none;
		color:$white;
		background:$primary;
		border:1px solid $primary;

	}

	&:focus {
		outline:none;
	}
}

.btn-remove {
	background:url(#{$url-statics}/images/close.svg) center no-repeat;
	background-size:contain;
	border:0;
	cursor:pointer;
	outline:none;
	padding:11px;
	position:absolute;
	right:10px;
	top:10px;
	transition: $transition-base;

	&:hover { opacity: .85; }
	&:focus {
		outline:none;
	}
}

.select2-container--default { 
		
		.select2-selection--single .select2-selection__rendered {
			margin:0;

			font-size:16px;

			@include media-breakpoint-up(sm)  {
				font-size:18px;
			}
		}

		.select2-selection--single {
			border:0;
			border-bottom: 1px solid rgba($black,.5);


		}

		.select2-results > .select2-results__options {
			font-size:16px;

			@include media-breakpoint-up(sm)  {
				font-size:18px;
			}
		}
	}


.form-group--calendar {
		position:relative;

		&:after {
			@extend %pseudos;
			width:33px;
			height:30px;
			background-size:cover;
			background-image:url(../images/ico-calendar.svg);
			bottom:5px;
			right:0;
			pointer-events:none;
			margin-top:-2px;

			@include media-breakpoint-up(sm)  { 
				width:48px;
				height:46px;
			}


		}
	}

.form-group--time {
	position:relative;

	@media (min-width:1160px) {
		display:inline-block;
		width:auto;
	}

	&:after {
		@extend %pseudos;
		width:33px;
		height:33px;
		background-size:cover;
		background-image:url(../images/hora.svg);
		bottom:5px;
		right:0;
		pointer-events:none;

		@include media-breakpoint-up(md)  { 
			width:48px;
			height:48px;
		}
	}

	input::-webkit-calendar-picker-indicator {
		cursor:pointer;
		opacity:0;
		width:calc(100% - 75px);
		height:33px;

		@include media-breakpoint-up(md)  { 
			height:48px;
		}
	}

	input {
		@media (min-width:1160px) {
			max-width:165px;
		}
	}
}

.form-group.form-group--time, .form-group--calendar {
	@media (min-width:1160px) {
		align-items:center;
		display:flex;
		flex-wrap: nowrap;

		span {
			margin-bottom:5px;
			width:100%;
		}
	}

	@include media-breakpoint-up(xl) { 
		#inscriptionDate { max-width:160px; }
	}
}



    	

    	