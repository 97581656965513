// ==========================================================================
// Container
// ==========================================================================


// 1.Config


// 2.Base
.container-fluid {
	padding:0 15px;
	max-width: 1400px;

	@include media-breakpoint-up(md) { 
		padding:0 40px;
	}

	@include media-breakpoint-up(xl) { 
		padding:0 60px;
	}	
}