// ==========================================================================
// Page Top
// ==========================================================================

// 1.Config


// 2.Base

.page-top {
	padding-top:40px;
	position:relative;
	overflow:hidden;

	/*&:after {
			@extend %pseudos;
			bottom: 0;			
			left: 0;
			width: 100%;
			height: 70%;
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
			opacity: 0.8;

			@include media-breakpoint-up(md)  { 
				opacity: 0.6;
				height: 50%;
			}

		}
*/

	.container-fluid {
		z-index: 2;
		position: relative;
	}

	&__bg {
    	position:absolute;
		width:100%; 
		height:100%;
		object-fit: cover;
		top: 0;		
		left: 0;
		object-position: 50% 50%; 

		    }

	&__container {
		//height:180px;
		position:relative;
		max-width: 1040px;
		margin: 0 auto;

		/*@include media-breakpoint-up(md)  { 
	    	height:410px;
	    }*/

	}

	&__caption {
		/*position: absolute;
		left:0;
		bottom:15px;
		max-width: 660px;

		@include media-breakpoint-up(md)  { 
	    	bottom:40px;
	    }*/
		
	}

	&__title {
		margin:0;
		font-weight:900;
		font-size: 37px;
		line-height: 41px;
		letter-spacing: -1px;
		//color:$white;
	

		@include media-breakpoint-up(sm)  { 
	    	font-size: 45px;
			line-height: 48px;
	    }

	    @include media-breakpoint-up(md)  { 
	    	font-size: 60px;
			line-height: 58px;
	    }
	}

	&__subtitle {
		margin:0;
		font-weight:900;
		font-size: 22px;
		line-height:1.2;
		letter-spacing: -1px;
		//color:$white;
	

		@include media-breakpoint-up(sm)  { 
	    	font-size: 35px;
	    }

	    @include media-breakpoint-up(md)  { 
	    	font-size: 30px;
	    }
	}
}

