$input-height:44px;
$input-height-lg:53px;
$input-height-xs:36px;
$input-bg-color:transparent;
$input-border-color:$black;
$base-transition:$transition-base;
$input-border-focus:transparent;
$input-color-text:$black;


.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width:100%!important;

    .select2-selection--single {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        height: $input-height-xs;

        @include media-breakpoint-up(sm) { 
          height: $input-height;
        }

        @include media-breakpoint-up(md) { 
          height: $input-height-lg;
        }

        .select2-selection__rendered {
          display: block;
          padding-left: 5px;
          padding-right: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; 
          color:$black;
          height:$input-height-xs;
          font-size:12px;

          @include media-breakpoint-up(sm) { 
            height: $input-height;
          }

          @include media-breakpoint-up(md) { 
            height: $input-height-lg;
          }
        }

        .select2-selection__clear {
          position: relative; 
        }
    }
}


.select2-dropdown {
  background-color: #fff;
  box-shadow:0 2px 4px 0 rgba(0, 0, 0, .25);
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; 

}

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 10px 15px;
  user-select: none;
  -webkit-user-select: none; 

  @include media-breakpoint-up(sm) { 
    padding: 15px 25px;
  }
}
.select2-results__option[aria-selected] {
    cursor: pointer; 
}

.select2-container--open .select2-dropdown {
  left: 0; 
  z-index:10000;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display:block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    border-width:1px;
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }
  .select2-search--dropdown .select2-search__field:focus {
    outline:none;
  }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  background-color: $input-bg-color;
  border: 1px solid $input-border-color;
  transition:$base-transition;
  //border-radius: 4px; 


}
  .select2-container--default .select2-selection--single .select2-selection__rendered {

    line-height: $input-height;
    padding-left:0;
    padding-right: 30px;
    text-align: left; 
  }


  .select2-container--focus.select2-container--default .select2-selection--single {
      //border-bottom:1px solid $input-border-focus;

  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    //transition:$transition-base;
    //height: 20px;
    position: absolute;
    top: 50%;
    font-size:10px;
    color:$black;
    right: 1px;
    width: 20px; 
    margin-top:-16px;

    &:before {
      color:$gray-300;
      content:'\e805';
      font-family:'fedefarma';
      font-size:17px;

      @include media-breakpoint-up(sm) { 
        font-size:20px;
      }
    }
  }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      display:none;
      border-color: $input-color-text transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
  transform:rotate(-180deg);
}


.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }



.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
 // -webkit-appearance: textfield; 
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 220px;
  overflow-y: auto; 
  color:#000;
          //@extend %font-light;
          //text-transform:uppercase;
          font-size:14px;


          @include media-breakpoint-up(sm) { 
            font-size:15px;
          }
        }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #fff; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #f2f2f2;
  color:#000;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2, .selection, .select2-selection.select2-selection--single, .select2-selection__rendered {
  outline:0;
}

.select2, .select2-container {
  &.error {
    .select2-selection--single {
      border-color:$red;
    }
  }
}
