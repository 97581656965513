// ==========================================================================
// Global Config
// ==========================================================================


//General
$version-statics: "1.0";
$url-statics: "../";
$image-path: "../images/";


//Colors
$primary:       #bb0a7e !default; //blau
$secondary:     #1f1f1f !default; //blau fosc
$white:         #fff !default;
$gray-100:      #F5F6F9 !default;
$gray-200:      #efefef !default;
$gray-300:      #747474 !default;
$gray-900:      #2b3648 !default;
$black:         #000 !default;

$light:         $gray-100 !default;
$dark:          $gray-900 !default;

$body-color:	 $black;
$body-bg:   	 $white;


 

//Fonts
$font-regular:               'M PLUS Rounded 1c', sans-serif!default;
$font-family-sans-serif:     -apple-system, BlinkMacSystemFont, "Segoe UI", 'M PLUS Rounded 1c', "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-base:           $font-regular;
$font-secondary:			 $font-regular;


//Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px
) !default;


//Grid
$grid-columns:                12 !default;
$grid-gutter-width:           50px !default;


//Container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;


//Transition
$transition-base:        	all .2s ease-in-out !default;



