/* 
SASS Bloilerplate
*/


/* ---------------------------------
 * Utilities
----------------------------------*/
@import "utilities/config"; 
@import "utilities/utils";

/* ---------------------------------
 * Vendors
 ----------------------------------*/
@import "node_modules/bootstrap/scss/bootstrap-grid";
@import "vendors/select2";
@import "vendors/flatpickr";

/* ---------------------------------  
 * Base 
 ----------------------------------*/
 @import "base/base"; 
 @import "base/fonts";
 @import "base/helpers";

/* ---------------------------------
 * Layout
 ----------------------------------*/ 

 @import "layout/container";
 @import "layout/header";
 @import "layout/footer";
 @import "layout/main"; 


/* ---------------------------------
 * Components
 ----------------------------------*/
 @import "components/page-top";
 @import "components/section-form";
 @import "components/forms";



