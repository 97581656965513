@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;500;700&display=swap');

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-v18-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/roboto-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v18-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v18-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v18-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/roboto-v18-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'),
       url('../fonts/roboto-v18-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v18-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v18-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v18-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v18-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'fedefarma';
  src: url('../fonts/fedefarma.eot?1949925');
  src: url('../fonts/fedefarma.eot?1949925#iefix') format('embedded-opentype'),
       url('../fonts/fedefarma.woff2?1949925') format('woff2'),
       url('../fonts/fedefarma.woff?1949925') format('woff'),
       url('../fonts/fedefarma.ttf?1949925') format('truetype'),
       url('../fonts/fedefarma.svg?1949925#fedefarma') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fedefarma';
    src: url('../font/fedefarma.svg?1949925#fedefarma') format('svg');
  }
} 
*/
 
 [class^="ico-"]:before, [class*=" ico-"]:before {
  font-family: "fedefarma";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.ico-arrow-up:before { content: '\e800'; } /* '' */
.ico-camera:before { content: '\e801'; } /* '' */
.ico-clip:before { content: '\e802'; } /* '' */
.ico-clock:before { content: '\e803'; } /* '' */
.ico-close:before { content: '\e804'; } /* '' */
.ico-down:before { content: '\e805'; } /* '' */
.ico-download:before { content: '\e806'; } /* '' */
.ico-envelope:before { content: '\e807'; } /* '' */
.ico-facebook:before { content: '\e808'; } /* '' */
.ico-googleplus:before { content: '\e809'; } /* '' */
.ico-icon-plus:before { content: '\e80a'; } /* '' */
.ico-icon-web:before { content: '\e80b'; } /* '' */
.ico-left:before { content: '\e80c'; } /* '' */
.ico-linkedin:before { content: '\e80d'; } /* '' */
.ico-mail:before { content: '\e80e'; } /* '' */
.ico-pdf-file:before { content: '\e80f'; } /* '' */
.ico-phone:before { content: '\e810'; } /* '' */
.ico-pointer:before { content: '\e811'; } /* '' */
.ico-right:before { content: '\e812'; } /* '' */
.ico-search:before { content: '\e813'; } /* '' */
.ico-up:before { content: '\e814'; } /* '' */
.ico-youtube:before { content: '\e815'; } /* '' */
.ico-share:before { content: '\e816'; } /* '' */
.ico-star:before { content: '\e817'; } /* '' */
.ico-twitter:before { content: '\e818'; } /* '' */
.ico-list:before { content: '\e819'; } /* '' */
.ico-check:before { content: '\e81a'; } /* '' */
.ico-no-check:before { content: '\e81b'; } /* '' */