// ==========================================================================
// Section form
// ==========================================================================

// 1.Config

// 2.Base

.section-form {
    padding:55px 0;
    overflow:hidden;

    &__form {
        display:block;
        position:relative;

        .loading {
            background: rgba($white,.6);
            display: none;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            div {
                animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                border: 8px solid #98226f;
                border-color: #98226f transparent transparent transparent;
                border-radius: 50%;
                box-sizing: border-box;
                display: block;
                height: 64px;
                left:50%;
                position: absolute;
                top:50%;
                transform:translate(-50%, -50%);
                width: 64px;
            }
            
            div:nth-child(1) {
                animation-delay: -0.45s;
            }
            
            div:nth-child(2) {
                animation-delay: -0.3s;
            }
            
            div:nth-child(3) {
                animation-delay: -0.15s;
            }
            
            @keyframes lds-ring {
                0% {
                    transform: rotate(0deg);
                }
                
                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }

    &__container {
        position:relative;
        max-width: 1040px;
        margin: 0 auto;
    }

    &__title {
        margin:0 0 20px;
        font-weight:500;
        font-size: 28px;

        @include media-breakpoint-up(md)  { 
            font-size: 40px;
        }

    }

    &__text {
        margin:15px 0 30px;
        color:#1f1f1f;
        font-size: 14px;
        line-height:1.5;
        max-width:640px;
        
        @include media-breakpoint-up(md)  { 
            font-size: 16px;
        }

    }

    &__guest-container {
        margin-top:30px;
        counter-reset: block;  
    }

    &__guest-block {
         
        margin:0 0 20px;
        padding:30px 20px 30px 60px;
        
        background-color:#f2f2f2;
        position:relative;

        @include media-breakpoint-up(md)  { 
            padding:30px 60px 30px 80px;
        }


        &:after {
            counter-increment: block;                   
            content: counter(block);
            position:absolute;
            width:42px;
            height:42px;    
            border: solid 2px #1f1f1f; 
            border-radius:50%;
            line-height:40px;
            text-align:center;
            top:5px;
            left:5px;
            font-size: 15px;
            font-weight: 500;
            color:#1f1f1f;

            @include media-breakpoint-up(md)  { 
                top:10px;
                left:10px;
            }
        }

        &:first-child .btn-remove {
            display:none;
        }
    }

    .inscription-ok {
        display:none;
        
        &__box {
            background:#e0ffe0;
            padding:20px;
        }

        .section-form__text {
            font-size: 16px;
            max-width:850px;

            @include media-breakpoint-up(md) { 
                font-size: 25px;
            }
        }
    }

    .error-message {
        color:#dc3545;
        display:none;
        font-size:12px;
        margin-top:5px;
    }

}




