// ==========================================================================
// Main
// ==========================================================================


// 1.Config


// 2.Base

.main {
	padding-top: 61px;

    @include media-breakpoint-up(md)  { 
        padding-top: 101px;  
    }

    @include media-breakpoint-up(lg)  { 
    	min-height:calc(100vh - 345px);
    }
	
	position:relative;
	z-index:0;
	
}


